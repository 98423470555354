import React from "react";
import './index.css';
import InstallButton from '../../../pageComponent/InstallButton';
import Footer from '../../../pageComponent/Footer';
import MacWithLogo from '../../MacWithLogo';
import Logo from '../../../../static/logos/logo.svg';
import HowItWorksHeroImage from '../../../../static/nyc-view.png';
import HowItWorksHeroImageMobile from '../../../../static/nyc-view-mobile.png';
import { checkIsMobile } from '../../../utils/userAgentParsing';


export default class HowItWorksContent extends React.Component {
  constructor() {
    super();
    this.state = {
      isMobile: false
    };
  }

  componentDidMount() {
    const isMobileValue = checkIsMobile();
    if (this.state.isMobile !== isMobileValue) {
      setTimeout(() => {
        this.setState({
          isMobile: isMobileValue
        });
      });
    }
  }

  render() {
    const { isMobile } = this.state;

    return (
      <React.Fragment>
        <div className="how-it-works-content-wrapper">
          <div className="how-it-works-hero-container">
            <div className="how-it-works-hero-inner-container">
              <div
                className="how-it-works-hero-image"
                style={{ backgroundImage: `url(${isMobile ? HowItWorksHeroImageMobile : HowItWorksHeroImage})` }}
              >
                <div className="how-it-works-hero-gradient" />
              </div>
              <div className="how-it-works-hero-text-container">
                <div className="main-text how-it-works-main-text how-it-works-hero-text how-it-works-hero-text-top">
                  Want long term, affordable housing in the world’s least affordable city?
                </div>
                <div className="main-text how-it-works-main-text how-it-works-hero-text how-it-works-hero-text-middle">
                  We did!
                </div>
                <div className="sub-text how-it-works-sub-text how-it-works-hero-text how-it-works-hero-text-bottom">
                  We started afford.nyc in an effort to find our own apartments… ones that we could actually call our home, even though we couldn't afford to buy an NYC apartment.
                </div>
              </div>
            </div>
          </div>
          <div className="how-it-works-secret">
            <div className="how-it-works-bubble-base how-it-works-bubble-complement" />
            <div className="how-it-works-bubble-base how-it-works-bubble" />
            <div className="how-it-works-secret-header main-text">
              What&apos;s our secret?
            </div>
            <div className="how-it-works-secret-text">
              We use algorithms built on public and private datasets to predict with <b>98%</b> accuracy whether or not an apartment listing is rent-stabilized.
            </div>
          </div>
          <div className="how-it-works-how">
            <div className="how-it-works-bubble-base how-it-works-bubble-complement-desktop" />
            <div className="main-text how-it-works-main-text how-it-works-how-word">
              How?
            </div>
            <div className="sub-text how-it-works-sub-text how-it-works-how-word-sub">
              With afford.nyc, it’s simple to find your affordable home.
            </div>
            <ul className="how-it-works-list">
              <li className="how-it-works-list-item">
                <div className="main-text how-it-works-list-item-number">
                  1.
                </div>
                <div className="main-text how-it-works-list-item-text how-it-works-first-list-item-text">
                  Install the Chrome Extension
                </div>
              </li>
              <li className="how-it-works-list-item">
                <div className="main-text how-it-works-list-item-number">
                  2.
                </div>
                <div className="main-text how-it-works-list-item-text">
                  Run your apartment search on Streeteasy or Zillow
                </div>
              </li>
              <li className="how-it-works-list-item">
                <div className="main-text how-it-works-list-item-number">
                  3.
                </div>
                <div className="main-text how-it-works-list-item-text">
                  Save properties with the Rent Stabilized Icon (<img className="how-it-works-inline-icon" alt="rent-stabilized icon" src={Logo} />)
                </div>
              </li>
            </ul>
            <div className="how-it-works-cta-container">
              <div className="how-it-works-mac-container">
                <MacWithLogo />
              </div>
              <div className="main-text how-it-works-cta-top-text">
                We’re making it easy to find your dream apartment
              </div>
              <div className="main-text how-it-works-cta-bottom-text">
                (that you can actually afford)
              </div>
              <InstallButton className="how-it-works-install-button" />
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
